import React, { useEffect, useState } from "react";
import RequestLogList from "../components/RequestLogList";
import Pagination from "../components/pagination/Pagination";
import axios from "axios";

export default function RequestLogs() {
  const [logs, setLogs] = useState([]);
  const [logsPerPage, setLogsPerPage] = useState(10);
  const [logsOffset, setLogsOffset] = useState(0);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const result = await axios.get("/api/logs");
        setLogs(result.data);
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    fetchLogs();
  }, []);

  return (
    <>
      <div className="main-content">
        <RequestLogList
          logsValue={logs}
          logsPerPage={logsPerPage}
          itemOffSet={logsOffset}
        />

        <Pagination
          itemsPerPage={logsPerPage}
          itemsData={logs}
          setItemOffsetValue={setLogsOffset}
          setItemsPerPage={setLogsPerPage}
        />
      </div>
    </>
  );
}
