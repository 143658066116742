import React from "react";
import UpdateTagForm from "../components/UpdateTagForm";
import { useParams } from "react-router-dom";

const EditTag = () => {
  const { id } = useParams();
  return (
    <div className="main-content">
      <h2>Edit TAG</h2>
      <UpdateTagForm tagId={id} />
    </div>
  );
};

export default EditTag;
