import React, { useEffect, useState } from "react";
import ApiList from "../components/ApiList";
import Pagination from "../components/pagination/Pagination";
import axios from "axios";

export default function APIs() {
  const [apis, setApis] = useState([]);
  const [apisPerPage, setApisPerPage] = useState(10);
  const [apisOffset, setApisOffset] = useState(0);

  useEffect(() => {
    const fetchApis = async () => {
      const result = await axios("/api/apis");
      setApis(result.data);
    };
    fetchApis();
  }, []);

  return (
    <div className="main-content">
      <ApiList
        apisValue={apis}
        apisPerPage={apisPerPage}
        itemOffSet={apisOffset}
      />

      <Pagination
        itemsPerPage={apisPerPage}
        itemsData={apis}
        setItemOffsetValue={setApisOffset}
        setItemsPerPage={setApisPerPage}
      />
    </div>
  );
}
