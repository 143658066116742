// src/pages/Dashboard.js
import React from "react";

const Home = () => (
  <div className="main-content">
    <h1>API Management Dashboard</h1>
    <p>
      This application provides a streamlined way to manage APIs and dynamically
      control their behavior through the use of tags. Key features include:
    </p>

    <ul>
      <li>
        <strong>View and Manage APIs:</strong> You can see a list of all
        configured APIs, including their routes, methods, and whether they use
        tags. Each API can be edited or deleted directly from the dashboard.
      </li>

      <li>
        <strong>Tags and API Interaction:</strong> Tags are key-value pairs that
        dynamically modify API responses. When an API request is received, any
        tags associated with the API can replace predefined values in the
        response with data from the incoming request. Tags are not specific to
        any single API but can be applied universally across all APIs.
      </li>

      <li>
        <strong>Request Logs:</strong> Keep track of incoming requests with the
        request log feature. You can view the most recent requests for each API
        to monitor performance and troubleshoot issues.
      </li>

      <li>
        <strong>User-Friendly Management:</strong> Add, update, or delete APIs
        and their associated tags easily with a modern interface. Tags help make
        the API responses more flexible and adaptable based on user requests.
      </li>
    </ul>
  </div>
);

export default Home;
