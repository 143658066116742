import React, { useEffect, useState } from "react";
import TagsList from "../components/TagsList.js";
import Pagination from "../components/pagination/Pagination.js";
import axios from "axios";

export default function TAGs() {
  const [tags, setTags] = useState([]);
  const [tagsPerPage, setTagsPerPage] = useState(10);
  const [tagsOffset, setTagsOffset] = useState(0);

  useEffect(() => {
    const fetchTags = async () => {
      const result = await axios("/api/tags");
      setTags(result.data);
    };
    fetchTags();
  }, []);

  return (
    <div className="main-content">
      <TagsList
        tagsValue={tags}
        tagsPerPage={tagsPerPage}
        itemOffSet={tagsOffset}
      />

      <Pagination
        itemsPerPage={tagsPerPage}
        itemsData={tags}
        setItemOffsetValue={setTagsOffset}
        setItemsPerPage={setTagsPerPage}
      />
    </div>
  );
}
