import React from "react";

function RequestLogList({ logsValue, logsPerPage, itemOffSet }) {
  const endOffset = itemOffSet + logsPerPage;
  const currentItems = logsValue.slice(itemOffSet, endOffset);

  const convertToMySQLDateTime = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div>
      <h2>Request Logs</h2>
      <table>
        <thead>
          <tr>
            <th>API Route</th>
            <th>API ID</th>
            <th>Method</th>
            <th>Request Body</th>
            <th>RESPONSE Body</th>
            <th>Timestamp</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((log) => (
            <tr key={log.ID}>
              <td>{log.ROUTE}</td>
              <td>{log.API_ID}</td>
              <td>{log.METHOD}</td>
              <td>
                <div className="response">{log.BODY}</div>
              </td>
              <td>
                <div className="response">{log.RESPONSE}</div>
              </td>
              <td>{convertToMySQLDateTime(log.TIMESTAMP)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RequestLogList;
