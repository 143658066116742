// src/components/Header.js
import React from "react";
import { Link } from "react-router-dom";

const Header = () => (
  <header>
    <h1>API MOCK SERVICE</h1>
    <nav>
      <Link to="/">Home</Link>
      <span></span>
      <Link to="/manage-apis">APIs</Link>
      <span></span>
      <Link to="/create-api">Create API</Link>
      <span></span>
      <Link to="/tags">TAGs</Link>
      <span></span>
      <Link to="/logs">Request Logs</Link>
    </nav>
  </header>
);

export default Header;
