import React from "react";
import CreateApiForm from "../components/CreateApiForm";

const CreateApi = () => (
  <div className="main-content">
    <h2>Create New API</h2>
    <CreateApiForm />
  </div>
);

export default CreateApi;
