// src/components/TagList.js
import React from "react";
import axios from "axios";

function TagList({ tagsValue, tagsPerPage, itemOffSet }) {
  const endOffset = itemOffSet + tagsPerPage;
  const currentItems = tagsValue.slice(itemOffSet, endOffset);

  const handleDelete = async (id) => {
    try {
      await axios
        .delete(`/api/manageTAG/${id}`)
        .then(() => window.location.reload());
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  return (
    <div>
      <h2>All Tags</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Key</th>
            <th>SOURCE PATH</th>
            <th>API ID</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((tag) => (
            <tr key={tag.ID}>
              <td>{tag.ID}</td>
              <td>{tag.KEY}</td>
              <td>{tag.VALUE}</td>
              <td>{tag.API_ID}</td>
              <td>
                <button
                  id="action"
                  onClick={() => (window.location.href = `/edit-tag/${tag.ID}`)}
                >
                  Edit
                </button>
                <button id="action" onClick={() => handleDelete(tag.ID)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TagList;
