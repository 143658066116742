import React, { useState } from "react";
import axios from "axios";

const CreateTagForm = ({ apiID }) => {
  const [tag, setTag] = useState({
    KEY: "",
    VALUE: "",
    API_ID: apiID,
  });

  const handleChange = (e) => {
    setTag({ ...tag, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/manageTAG", tag)
      .then(() => (window.location.href = "/tags"));
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        KEY:
        <input
          type="text"
          name="KEY"
          value={tag.KEY}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        VALUE:
        <input
          type="text"
          name="VALUE"
          value={tag.VALUE}
          onChange={handleChange}
          required
        />
      </label>
      <button type="submit">Save</button>
    </form>
  );
};

export default CreateTagForm;
