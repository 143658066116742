import React, { useState, useEffect } from "react";
import axios from "axios";

const CreateApiForm = ({ apiId }) => {
  const [api, setApi] = useState({
    ROUTE: "",
    METHOD: "",
    CONTENT_TYPE: "",
    RESPONSE: "",
    STATUS_CODE: "",
  });

  const handleChange = (e) => {
    setApi({ ...api, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchApis = async (id) => {
      const result = await axios(`/api/apis/${id}`);
      setApi({
        ROUTE: result.data[0].ROUTE,
        METHOD: result.data[0].METHOD,
        CONTENT_TYPE: result.data[0].CONTENT_TYPE,
        RESPONSE: result.data[0].RESPONSE,
        STATUS_CODE: result.data[0].STATUS_CODE,
      });
    };

    if (apiId) {
      fetchApis(apiId);
    }
  }, [apiId]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (apiId) {
      axios
        .put(`/api/manageAPI/${apiId}`, api)
        .then(() => (window.location.href = "/manage-apis"));
    } else {
      axios
        .post("/api/manageAPI", api)
        .then(() => (window.location.href = "/manage-apis"));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Route:
        <input
          type="text"
          name="ROUTE"
          value={api.ROUTE}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Method:
        <select
          name="METHOD"
          value={api.METHOD || "default"}
          onChange={handleChange}
          required
        >
          <option value="default" disabled>
            Select a METHOD
          </option>
          <option value="POST">POST</option>
          <option value="GET">GET</option>
          <option value="PUT">PUT</option>
          <option value="DELETE">DELETE</option>
        </select>
      </label>
      <label>
        Content Type:
        <input
          type="text"
          name="CONTENT_TYPE"
          value={api.CONTENT_TYPE}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Response:
        <textarea
          rows="4"
          cols="50"
          type="text"
          name="RESPONSE"
          value={api.RESPONSE}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        Status Code:
        <input
          type="number"
          name="STATUS_CODE"
          value={api.STATUS_CODE}
          onChange={handleChange}
          required
        />
      </label>
      <button type="submit">Save</button>
    </form>
  );
};

export default CreateApiForm;
