import React from "react";
import axios from "axios";

function ApiList({ apisValue, apisPerPage, itemOffSet }) {
  const endOffset = itemOffSet + apisPerPage;
  const currentItems = apisValue.slice(itemOffSet, endOffset);

  return (
    <div>
      <h2>APIs</h2>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Routes</th>
            <th>Method</th>
            <th>Response</th>
            <th>Tags</th>
            <th>Status Code</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((api) => (
            <tr key={api.ID}>
              <td>{api.ID}</td>
              <td>{api.ROUTE}</td>
              <td>{api.METHOD}</td>
              <td>
                <div className="response">{api.RESPONSE}</div>
              </td>
              <td>{api.TAGs}</td>
              <td>{api.STATUS_CODE}</td>
              <td>
                <div className="actions">
                  <button
                    id="action"
                    onClick={() =>
                      (window.location.href = `/create-tag/${api.ID}`)
                    }
                  >
                    Add Tag
                  </button>
                  <button
                    id="action"
                    onClick={() =>
                      (window.location.href = `/edit-api/${api.ID}`)
                    }
                  >
                    Edit
                  </button>
                  {!api.TAGs && (
                    <button
                      id="action"
                      onClick={() => {
                        if (
                          window.confirm(
                            "Are you sure you want to delete this API?"
                          )
                        ) {
                          axios
                            .delete(`/api/manageAPI/${api.ID}`)
                            .then(() => window.location.reload());
                        }
                      }}
                    >
                      Delete
                    </button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ApiList;
