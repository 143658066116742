import React from "react";
import CreateTagForm from "../components/CreateTagForm";
import { useParams } from "react-router-dom";

const CreateApi = () => {
  const { id } = useParams();
  return (
    <div className="main-content">
      <h2>Create New TAG</h2>
      <CreateTagForm apiID={id} />
    </div>
  );
}

export default CreateApi;
