import React, { useState, useEffect } from "react";
import axios from "axios";

const CreateTagForm = ({ tagId }) => {
  const [tag, setTag] = useState({
    KEY: "",
    VALUE: "",
    API_ID: "",
  });

  const handleChange = (e) => {
    setTag({ ...tag, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchTags = async (id) => {
      const result = await axios(`/api/tags/${id}`);
      setTag({
        KEY: result.data[0].KEY,
        VALUE: result.data[0].VALUE,
        API_ID: result.data[0].API_ID,
      });
    };

    if (tagId) {
      fetchTags(tagId);
    }
  }, [tagId]);

  const handleSubmit = (e) => {
    e.preventDefault();
      axios
        .put(`/api/manageTAG/${tagId}`, tag)
        .then(() => (window.location.href = "/tags"));
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        KEY:
        <input
          type="text"
          name="KEY"
          value={tag.KEY}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        VALUE:
        <input
          type="text"
          name="VALUE"
          value={tag.VALUE}
          onChange={handleChange}
          required
        />
      </label>
      <label>
        API_ID:
        <input
          type="text"
          name="API_ID"
          value={tag.API_ID}
          onChange={handleChange}
          required
        />
      </label>
      <button type="submit">Save</button>
    </form>
  );
};

export default CreateTagForm;
