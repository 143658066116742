import React from "react";
import CreateApiForm from "../components/CreateApiForm";
import { useParams } from "react-router-dom";

const EditApi = () => {
  const { id } = useParams();
  return (
    <div className="main-content">
      <h2>Edit API</h2>
      <CreateApiForm apiId={id} />
    </div>
  );
};

export default EditApi;
