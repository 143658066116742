// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import APIs from "./pages/APIs";
import TAGs from "./pages/TAGs";
import Home from "./pages/Home";
import CreateApi from "./pages/CreateApi";
import EditApi from "./pages/EditApi";
import CreateTag from "./pages/CreateTag";
import EditTag from "./pages/EditTag";
import RequestLogs from "./pages/RequestLogs";

const App = () => (
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/manage-apis" element={<APIs />} />
      <Route path="/create-api" element={<CreateApi />} />
      <Route path="/edit-api/:id" element={<EditApi />} />
      <Route path="/logs" element={<RequestLogs />} />
      <Route path="/tags" element={<TAGs />} />
      <Route path="/create-tag/:id" element={<CreateTag />} />
      <Route path="/edit-tag/:id" element={<EditTag />} />
    </Routes>
  </Router>
);

export default App;
